<template>
  <div class=" w100 role-limit-box ">
    <el-tabs
      v-model="activeName"
      @tab-click="tabFn"
      class="tab_box"
      ref="tab_box_dom"
    >
      <el-tab-pane
        :label="cell.name"
        :name="cell.name"
        v-for="cell in tabs"
        :key="cell.id"
      >
      </el-tab-pane
    ></el-tabs>
    <!--   v-if="cell.name == activeName" -->
    <el-table
      :data="applySourceList"
      ref="table"
      style="width: 100%;"
      row-key="code"
      :stripe="true"
      border
      :indent="60"
      :default-expand-all="true"
      :header-cell-style="{ 'text-align': 'left ' }"
      :cell-style="{ 'text-align': 'left ' }"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <!-- :max-height="tableHight + 'px'" -->
      <el-table-column label="资源编号">
        <template slot-scope="{ row }">
          <span
            v-if="row.parentId == undefined"
            class="db"
            :style="{
              'margin-top': row.children.length ? '-20px' : '0',
              'margin-left': '20px'
            }"
            >{{ row.code }}</span
          >
          <span v-else style="margin-left:50px;margin-top:-20px" class="db">
            {{ row.code }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="资源名称" prop="name" />
      <el-table-column label="资源描述" prop="description" />
      <el-table-column label="是否授权" width="80px">
        <template slot-scope="{ row, $index }" v-if="row.parentNode != 'none'"
          ><span
            class="el-icon-circle-check auth  font18 pointer"
            @click="setAuthorFn(row)"
            :class="{ sureAuth: row.authorFlag }"
          />
        </template>
      </el-table-column>
    </el-table>
    <p v-if="!tabs.length" class="flex-center">暂无数据，请先配置空间应用</p>
  </div>
</template>

<script>
import { tenants, tenant_sources } from '@/api/space_menus';
// import { mapGetters } from 'vuex';
export default {
  name: 'role_limit_role',
  props: {
    spaceId: '',
    alreadyAuthorData: {
      type: Array
    }
  },
  data() {
    return {
      defaultProps: {
        label: 'label',
        children: 'children'
      },
      activeName: '',
      tabs: [], //应用数据
      appAllData: {},
      tableList: [],
      currentTabApplyTableData: {},
      applySourceList: [], //应用资源
      allSourceList: []
      // tableHight: window.innerHeight - 480,
    };
  },
  mounted() {
    this.getTabTenantList(() => {
      this.tabFn();
    });
    let that = this;
    // window.onresize = () => {
    //   return (() => {
    //     that.tableHight = window.innerHeight - 480;
    //   })();
    // };
  },
  watch: {
    alreadyAuthorData(newVal) {
      this.setAuthoredData(newVal, this.allSourceList);
    }
  },
  methods: {
    //授权
    setAuthorFn(v) {
      let authorOrCancel = v.authorFlag ? true : false;
      this.$emit('setAuthor', {
        tenantPermissionId: v.tenantPermissionId.toString(),
        authorOrCancel: authorOrCancel
      });
    },
    tabFn() {
      this.currentTabApplyTableData = this.filterTabData();
      if (!this.currentTabApplyTableData) {
        return;
      }
      this.get_source_table();
    },
    //已经授权的回显
    setAuthoredData(alreadyAuthorData, allSourceArr) {
      let a = [...allSourceArr];
      let newData = {};
      alreadyAuthorData.forEach(cell => {
        newData[cell.tenantPermissionId.toString()] = cell;
      });
      allSourceArr.forEach(item => {
        item.authorFlag = newData[item.tenantPermissionId.toString()]
          ? true
          : false;
      });
      // console.log(newData, 'newData===')
      this.applySourceList = Object.values(this.setTableData(a)) || [];
    },
    filterTabData() {
      let a = this.tabs.filter(ele => {
        return ele.name == this.activeName;
      });
      return a.length ? a[0] : null;
    },
    //资源表格数据
    async get_source_table() {
      const tenantId = this.currentTabApplyTableData.tenantId;
      const res = await tenant_sources({ tenantId: tenantId });
      this.allSourceList = res || [];
      this.setAuthoredData(this.alreadyAuthorData, this.allSourceList);
    },
    setTableData(arr) {
      let newList = {};
      let noParentNodes = {
        name: '未知资源',
        children: [],
        code: '未知code',
        parentNode: 'none'
      };
      arr.forEach(ele => {
        let obj = ele;
        if (ele.parentId == undefined) {
          //一级
          obj.children = [];
          newList[obj.rootId] = obj;
        } else {
          if (newList[ele.parentId]) {
            obj.parentId = ele.parentId.toString();
            newList[ele.parentId].children.push(obj);
          } else {
            obj.parentNode = 'none';
            noParentNodes.children.push(obj);
          }
        }
      });
      return noParentNodes.children.length
        ? { ...newList, noParentNodes }
        : newList;
    },
    setApplysList(arr) {
      this.appAllData = {};
      if (!arr.length) return;
      arr.forEach(cell => {
        this.appAllData[cell.appId.toString()] = cell;
      });
    },
    //该空间租户
    async getTabTenantList(cb) {
      const res = await tenants({
        spaceId: this.spaceId
      });
      this.tabs = res || [];
      this.activeName = this.tabs.length ? this.tabs[0].name : '';
      cb && cb();
    }
  }
};
</script>

<style lang="scss" scoped>
.role-limit-box {
  background: #fff;
}
.tab_box {
  ::v-deep {
    .el-tabs__nav-wrap::after {
      height: 0;
    }
    // .el-tabs__header {
    //   // background: #ccc;
    // }
  }
}
.auth {
  color: #e4e3e3;
}
.sureAuth {
  color: #409eff;
}
.isFixed {
  background: #fff;
  width: 67%;
  position: fixed;
  top: 100px;
  // left: 30%;
  // z-index: 4;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" w100 role-limit-box "},[_c('el-tabs',{ref:"tab_box_dom",staticClass:"tab_box",on:{"tab-click":_vm.tabFn},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.tabs),function(cell){return _c('el-tab-pane',{key:cell.id,attrs:{"label":cell.name,"name":cell.name}})}),1),_c('el-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.applySourceList,"row-key":"code","stripe":true,"border":"","indent":60,"default-expand-all":true,"header-cell-style":{ 'text-align': 'left ' },"cell-style":{ 'text-align': 'left ' },"tree-props":{ children: 'children', hasChildren: 'hasChildren' }}},[_c('el-table-column',{attrs:{"label":"资源编号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.parentId == undefined)?_c('span',{staticClass:"db",style:({
            'margin-top': row.children.length ? '-20px' : '0',
            'margin-left': '20px'
          })},[_vm._v(_vm._s(row.code))]):_c('span',{staticClass:"db",staticStyle:{"margin-left":"50px","margin-top":"-20px"}},[_vm._v(" "+_vm._s(row.code)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"资源名称","prop":"name"}}),_c('el-table-column',{attrs:{"label":"资源描述","prop":"description"}}),_c('el-table-column',{attrs:{"label":"是否授权","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
          var $index = ref.$index;
return (row.parentNode != 'none')?[_c('span',{staticClass:"el-icon-circle-check auth  font18 pointer",class:{ sureAuth: row.authorFlag },on:{"click":function($event){return _vm.setAuthorFn(row)}}})]:undefined}}],null,true)})],1),(!_vm.tabs.length)?_c('p',{staticClass:"flex-center"},[_vm._v("暂无数据，请先配置空间应用")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }